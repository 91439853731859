<template>
    <el-container class="full-container">
        <el-header class="shadow">
            <admin-header></admin-header>
        </el-header>
        <el-container class="main-container">
            <el-aside width="250px">
                <side class="side" ref="side" :list="menuData.homeMenuList"></side>
            </el-aside>
            <el-main>
                <router-view/>
            </el-main>
        </el-container>
        <notice-msg></notice-msg>
    </el-container>
</template>

<script>
import adminHeader from '@/views/main/components/adminHeader';
import adminMain from '@/views/main/components/adminMain';
import noticeMsg from '@/views/main/components/noticeMsg';
import Side from "@/views/setting/components/side";
import {mapState} from "vuex";

export default {

    components: {
        Side,
        adminHeader,
        adminMain,
        noticeMsg
    },

    computed: {
        ...mapState('login', ['menuData'])
    },

    data() {
        return {
            menuList: []
        }
    },

    mounted() {
        // let list = this.$message.homeMenu;
        // this.menuList = list;
        console.log('$route', this.$route)
    },

    methods: {

        /**
         * 获取产品详情
         * @param page
         */
        getProductMsg(page) {
            this.$refs.adminMain.getProductMsg(page)
        }

    }

}
</script>

<style scoped>
.full-container {
    height: 100vh;
    overflow: hidden;
}

.shadow {
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    z-index: 20;
    height: 64px !important;
    padding: 0px;
}

.main-container {
    background-color: #F0F2F5;
    height: 100%;
    overflow-y: hidden;
}

.el-main {
    padding: 0px;
    /*animation: fadeInRight; !* referring directly to the animation's @keyframe declaration *!*/
    /*animation-duration: 1s; !* don't forget to set a duration! *!*/
    overflow-x: hidden;
}

.el-aside {
    z-index: 9;
    background-color: #ffffff;
    box-shadow: 0 5px 4px 0 #E1E1E1;
    text-align: left;
    padding: 6px 5px;
    animation: fadeInLeft; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

.el-header {
    animation: fadeInDown; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

.main-container::-webkit-scrollbar {
    width: 8px;
    height: 0px;
    background: #f2f2f2;
    padding-right: 2px;
}
</style>
